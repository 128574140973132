<template>
  <div class="columns is-multiline" id="auth-login">
    <div class="column is-3" />
    <div class="column is-6">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <h2 class="is-size-2 forgot-password-page__title">Forgot Password</h2>
          <div class="is-size-4 forgot-password-page__subtitle">
            Please input your email, and we will sent you a message to reset
            your password
          </div>
          <br />
          <validation-provider
            name="email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-field
              label="Email"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
              class="forgot-password-field-padding"
            >
              <b-input
                placeholder="e.g admin@roketin.com"
                v-model="email"
                size="is-size-5"
              ></b-input>
            </b-field>
          </validation-provider>
          <b-field class="is-flex is-justify-content-center">
            <b-button
              :loading="isLoading"
              native-type="submit"
              class="is-size-5 mt-2 mb-2"
              expanded
            >
              Submit
            </b-button>
          </b-field>
        </form>
      </ValidationObserver>
      <div class="auth__form__footer">
        <div class="forgot-password-page__remember-pass">
          {{ `Remember your password? ` }}
          <span>
            <router-link to="login" class="forgot-password-login-link">
              Login
            </router-link>
          </span>
        </div>
      </div>
    </div>
    <div class="column is-3" />
  </div>
</template>
<script>
import { showToast } from '@/services/util'

export default {
  data() {
    return {
      email: null,
      isLoading: false,
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      let email = this.email
      await this.$store
        .dispatch('auth/forgotPassword', { email })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: `Email Has Been Sent!`,
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            showToast(error.response.data.message, 'is-danger', 'is-top')
          }
        })
      this.isLoading = false
    },
  },
}
</script>
